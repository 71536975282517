<template>
  <v-navigation-drawer width="300" :temporary="true">
    <v-list>
      <v-list-item
        :prepend-avatar="logo"
        :title="user.get_full_name"
        :subtitle="user.username"
      ></v-list-item>
    </v-list>

    <v-list v-for="section in drawerSections">
      <v-list-subheader>{{ $t(section.title) }}</v-list-subheader>

      <span v-for="item in section.paths">
        <v-list-item
          v-if="item.link_type == 'internal'"
          color="primary"
          :prepend-icon="item.icon"
          :title="$t(item.title)"
          variant="text"
          :to="section.path + item.path"
          router
        ></v-list-item>
        <v-list-item
          v-else
          color="primary"
          :prepend-icon="item.icon"
          :title="$t(item.title)"
          variant="text"
          :href="item.path"
          target="_blank"
        ></v-list-item>
      </span>
    </v-list>

    <template v-slot:append>
      <v-row class="pb-2">
        <v-col>
          <v-btn @click="$emit('logout')" variant="text" color="grey">
            <v-icon>mdi-exit-to-app</v-icon>
            <span class="mx-2">{{ $t("logout") }}</span>
          </v-btn>
        </v-col>

        <!-- <v-spacer></v-spacer> -->

        <!-- <v-col>
          <v-btn to="/feedback" variant="text" color="grey">
            <v-icon>mdi-bug</v-icon>
            <span class="mx-2">{{ $t("feedback") }}</span>
          </v-btn>
        </v-col> -->
      </v-row>
    </template>
  </v-navigation-drawer>
</template>

<script setup>
import { computed } from "vue";
import { useS3 } from "@/composables/useS3";
import { useUserStore } from "@/stores/user";
import navigation_list from "@/router/routes.json";

const props = defineProps(["app_name"]);
const s3 = useS3();
const user = useUserStore();
const logo = s3.get(s3.Bucket.PUBLIC, "sick.png");

const drawerSections = computed(() => {
  return navigation_list.filter(
    (section) => section.title !== "administration" || user.is_superuser
  );
});
</script>

<style>
div.v-list-item__prepend > div > div > img {
  object-fit: scale-down;
}
</style>
