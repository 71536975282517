<template>
  <template
    v-for="(question, i) in section.questions"
    :key="question.label"
    class="pl-3 ma-0 pr-0"
  >
    <div v-show="checkVisibility(question)">
      <v-divider v-if="i != 0" class="my-3"></v-divider>

      <v-row align="center" justify="space-between">
        <v-col class="mb-2">
          <v-label>
            {{ getQuestionLabel(section, question) }}
          </v-label>
        </v-col>

        <v-col
          style="display: flex; align-items: center; justify-content: right"
          cols="2"
        >
          <!-- STAR Icon -->
          <div>
            <v-icon
              end
              color="primary"
              size="small"
              v-if="question.highlight_level === '2'"
            >
              mdi-star
            </v-icon>
            <v-icon
              end
              color="primary"
              size="small"
              v-if="question.highlight_level === '1'"
            >
              mdi-star-outline
            </v-icon>
            <v-tooltip activator="parent" location="bottom">
              {{ $t("system_request__question_filter_icon_tooltip") }}
            </v-tooltip>
          </div>

          <!-- HELP/ INFO Icon -->
          <div v-if="showInfo(question)">
            <v-menu open-on-hover>
              <template v-slot:activator="{ props }">
                <v-icon
                  end
                  color="primary"
                  size="small"
                  v-bind="props"
                  style="cursor: pointer"
                >
                  mdi-information
                </v-icon>
              </template>

              <v-sheet
                style="
                  background-color: #3f3f3f;
                  color: white;
                  white-space: pre-line;
                  font-size: 14px;
                "
                class="pa-4"
              >
                {{ showInfo(question) }}
              </v-sheet>
            </v-menu>
          </div>

          <!-- LINK Icon -->
          <div v-if="question.help_url">
            <v-tooltip activator="parent" location="bottom">
            {{ $t("systems_request_help_url") }}
          </v-tooltip>
            <v-icon
            end
              size="small"
              style="cursor: pointer"
              @click="showInfoPage(question)"
              color="primary"
            >
              mdi-link-variant
            </v-icon>
          </div>
        </v-col>
      </v-row>

      <v-row dense align="center" justify="space-between">
        <v-col v-if="question.image" :cols="2">
          <v-img width="60" :src="question.image" color="white" />
        </v-col>
        <v-col :cols="calculateQuestionSpace(question)">

          <BaseBinaryInputField
            v-if="question.type === 'binary-input'"
            v-model="request[question.data_field]"
            :question="question"
            @switchClicked="requestStore.get_solutions(); autosave.save()"
          />

          <BaseTextInputField
            v-else-if="question.type === 'text-input'"
            v-model="
              request[
                question.data_field
                  ? question.data_field
                  : section.label + '__' + question.label
              ]
            "
            :question="question"
            :section_label="section.label"
            @chip-clicked="requestStore.get_solutions(); autosave.save()"
          />

          <BaseSlider
            v-else-if="question.type === 'slider'"
            v-model="
              request[
                question.data_field
                  ? question.data_field
                  : section.label + '__' + question.label
              ]
            "
            :question="question"
            :section_label="section.label"
            @slider-released="requestStore.get_solutions(); autosave.save()"
          />

          <BaseSliderRanged
            v-else-if="question.type === 'range-slider'"
            v-model="
              request[
                question.data_field
                  ? question.data_field
                  : section.label + '__' + question.label
              ]
            "
            :question="question"
            :section_label="section.label"
            @slider-released="requestStore.get_solutions(); autosave.save()"
          />

          <BaseSelect
            v-else
            v-model="
              request[
                question.data_field
                  ? question.data_field
                  : section.label + '__' + question.label
              ]
            "
            :question="question"
            :section_label="section.label"
            @chip-clicked="requestStore.get_solutions(); autosave.save()"
          />
          
        </v-col>
      </v-row>
    </div>
  </template>
</template>

<script setup>
import { ref, computed } from "vue";
import BaseSelect from "@/components/BaseSelect.vue";
import BaseSlider from "@/components/BaseSlider.vue";
import BaseSliderRanged from "@/components/BaseSliderRanged.vue";
import BaseTextInputField from "@/components/BaseTextInputField.vue";
import BaseBinaryInputField from "@/components/BaseBinaryInputField.vue";
import { storeToRefs } from "pinia";
import { useSystemRequestStore } from "@/stores/system_request";
import { useSettingsStore } from "@/stores/settings";
import { useI18n } from "vue-i18n";
import { useAutosaveStore } from "@/stores/autosave";

const { t, te } = useI18n();
const autosave = useAutosaveStore();

const settings = useSettingsStore();
const requestStore = useSystemRequestStore();
const { request } = storeToRefs(useSystemRequestStore());
const props = defineProps(["section"]);

function aredisjoint(set1, set2) {
  for (let i = 0; i < set1.length; i++) {
    for (let j = 0; j < set2.length; j++) {
      if (set1[i] == set2[j]) return false;
    }
  }
  return true;
}

function showInfo(question) {
  if (settings.locale === "en") {
    return question.help_text_en;
  } else if (settings.locale === "de") {
    return question.help_text_de;
  }
}

function showInfoPage(question) {
  window.open(question.help_url, "_blank");
}

function calculateQuestionSpace(question) {
  let cols = 12;
  if (question.image) {
    cols -= 2;
  }
  if (showInfo(question) !== "" || question.help_url) {
    cols -= 1;
  }
  return cols;
}

function checkVisibility(element) {
  if (element.includeIf == null) {
    return true;
  }

  for (let c in element.includeIf) {
    let condition = element.includeIf[c];
    let selection = request.value[condition.field];
    if (selection == null) {
      return false;
    }

    if (Array.isArray(selection)) {
      if (aredisjoint(condition.values, selection)) {
        return false;
      }
    } else {
      if (!condition.values.includes(selection)) {
        return false;
      }
    }
  }
  return true;
}

function getQuestionLabel(section, question) {
  let label;
  if (te("system_request_" + section.label + "_" + question.label)) {
    label = t("system_request_" + section.label + "_" + question.label);
  } else {
    label = question[settings.locale];
  }
  // if (label.slice(-1) === "*") {
  //   return label.slice(0, label.length - 1);
  // }
  return label;
}

// function showFilterIcon(section, question) {
//   let label;
//   if (te("system_request_" + section.label + "_" + question.label)) {
//     label = t("system_request_" + section.label + "_" + question.label);
//   } else {
//     label = question[settings.locale];
//   }
//   if (label.slice(-1) === "*") {
//     // return {
//     //   color: "#000000",
//     // };
//     return true;
//   }
//   // return {}
//   return false;
// }
</script>
