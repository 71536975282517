<template>
  <!-- v-model="settings.showSurveySelection" -->
  <v-overlay class="align-center justify-center" persistent opacity="0.1">
    <v-container fill-height fluid>
      <v-row justify="center" align="center">
        <v-col v-for="survey in props.surveyList" class="px-5">
          <v-card
            class="d-flex flex-column rounded-shaped"
            elevation="5"
            width="300"
            @click="updateSurvey(survey.questionnaire)"
            :disabled="false"
          >
            <a :href="survey.link" class="mx-2">
              <v-img width="320px" :src="survey.icon" min-height="320"></v-img>
            </a>
            <v-card-title>{{ survey.title }}</v-card-title>
            <v-card-text class="pt-3">
              <!-- <div>
                {{ survey.description }}
              </div> -->
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>

    <v-row align="center" justify="center" class="mt-5">
      <v-alert density="compact" max-height="70" max-width="400">
        <v-checkbox
          :label="$t('survey_selection_enable_survey_selection')"
          v-model="settings.surveySelectionEnabled"
        ></v-checkbox>
      </v-alert>
    </v-row>
  </v-overlay>
</template>

<script setup>
import { onMounted, ref } from "vue";
import { useI18n } from "vue-i18n";
import { useSystemRequestStore } from "@/stores/system_request";
import { useSettingsStore } from "@/stores/settings";
import { User } from "oidc-client-ts";
import { useUserStore } from "@/stores/user";

const userStore = useUserStore();

const { t } = useI18n();
const props = defineProps(["surveyList"]);
const system_request = useSystemRequestStore();
const settings = useSettingsStore();

function updateSurvey(survey) {
  settings.setShowSurveySelection(false);
  settings.setSelectedSurvey(survey);
  system_request
    .changeSurvey(survey)
    .then(() => system_request.get_solutions());
}
</script>

<style scoped>
html,
body {
  overflow: hidden !important;
}
.alignment_center {
  text-align: center;
  align-self: center;
  margin: 5px;
}
</style>
