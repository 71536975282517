<template>
  <ProjectActionPrompt ref="project_prompt" />

  <v-card id="application">
    <v-toolbar density="compact">
      <v-toolbar-title>
        {{ $t("application_specification") }}
      </v-toolbar-title>

      <!-- selected-class="survey-selected-class" -->
      <!-- base-color="grey-lighten-3" -->
      <!-- color="black" -->
      <!-- class="survey-group" -->
      <BaseToolbarMenu :items="toolbarMenuActions" />
    </v-toolbar>

    <v-row dense no-gutters justify="space-between">
      <div>
        <ApplicationStepperVertical
          v-model="tab"
          :items="sectionLabels"
          :survey-list="props.surveyList"
          :title="$t('system_request__section')"
        />
      </div>

      <v-col class="scrollable_content">
        <v-row no-gutters align="center">
          <v-breadcrumbs>
            {{
              currentSection ? currentSection : sectionLabels[0]
            }}</v-breadcrumbs
          >

          <!-- <v-col
            v-if="system_request.survey.name == 'SickAG'"
            style="display: flex; align-items: center; justify-content: right"
            class="pt-1"
          >
            <v-label class="pr-3">
              {{ $t("system_request__advanced_options") }}
            </v-label>

            <v-chip-group
              v-model="system_request.request.mode__sickag_mode"
              selected-class="text-primary"
              mandatory
              column
              filter
              variant="tonal"
              class="pr-2"
              @update:model-value="updateTab()"
            >
              <v-chip value="quick" selected-class="chip-selected">
                {{ $t("system_request__simple_mode") }}
              </v-chip>
              <v-chip value="detailed" selected-class="chip-selected">
                {{ $t("system_request__extended_mode") }}
              </v-chip>
            </v-chip-group>
          </v-col> -->
        </v-row>

        <v-card-text id="applicationCardText">
          <v-window disabled v-model="tab">
            <v-window-item
              v-for="(section, index) in sectionsVisible"
              :value="index + 1"
            >
              <v-alert
                type="info"
                variant="tonal"
                class="mb-5"
                border="end"
                v-if="section['help_text_' + settings.locale]"
              >
                {{ section["help_text_" + settings.locale] }}
              </v-alert>

              <ApplicationFormSection :section="section" />
            </v-window-item>
          </v-window>
        </v-card-text>

        <v-card-actions class="my-3">
          <v-btn
            v-if="tab > 1"
            rounded="xl"
            @click="tab = tab > 1 ? tab - 1 : 1"
          >
            <template v-slot:prepend="props">
              <v-icon>mdi-chevron-left</v-icon>
            </template>
            {{ $t("previous") }}
          </v-btn>

          <v-spacer></v-spacer>

          <v-btn
            v-if="tab < sectionsVisible.length"
            class="pl-3"
            rounded="xl"
            @click="tab = tab < sectionsVisible.length ? tab + 1 : tab"
          >
            <template v-slot:append="props">
              <v-icon>mdi-chevron-right</v-icon>
            </template>
            {{ $t("next") }}
          </v-btn>
          <!-- <v-btn variant="text" color="blue" icon="mdi-qrcode"> </v-btn> -->
        </v-card-actions>
      </v-col>
    </v-row>
  </v-card>
  <!-- <v-btn
  @click="updateQrCode()"
  >
    qr
  </v-btn>
  <v-img :src="qrCodeImg" alt="QR Code" /> -->
</template>

<script setup>
import router from "@/router";
import { ref, computed, onMounted, watch } from "vue";
import { storeToRefs } from "pinia";
import { useSystemRequestStore } from "@/stores/system_request";
import { useI18n } from "vue-i18n";
import { useSettingsStore } from "@/stores/settings";
import ProjectActionPrompt from "./ProjectActionPrompt.vue";
import ApplicationFormSection from "./ApplicationFormSection.vue";
import BaseToolbarMenu from "./BaseToolbarMenu.vue";
import { useWindowSize } from "@vueuse/core";
import { useApiSchemaAdapter } from "@/composables/useApiSchemaAdapter";
import useReportGenerator from "@/composables/useReportGenerator";
import ApplicationStepperVertical from "@/components/ApplicationStepperVertical.vue";
import { useQrCode } from "@/composables/useQrCode";
import { useAutosaveStore } from "@/stores/autosave";
import { useUserStore } from "@/stores/user";

const userStore = useUserStore();

const autosave = useAutosaveStore();
const qrCode = useQrCode();
const { height } = useWindowSize();
const { mapIfOld } = useApiSchemaAdapter();
const props = defineProps(["projectID", "params", "surveyList"]);
const settings = useSettingsStore();
const project_prompt = ref(null);
const { t, te } = useI18n();
const system_request = useSystemRequestStore();
const { request, solutions, project_active, project, selected } =
  storeToRefs(system_request);
const { downloadApplicationSpecCSV } = useReportGenerator(t, te);

const tab = ref(1);
const currentSection = ref("");

const sectionLabels = computed(() => {
  if (system_request.sections) {
    return system_request.sections
      .filter((section) => checkVisibility(section))
      .map((section) => {
        if (te("system_request_" + section.label)) {
          return t("system_request_" + section.label);
        }
        return section[settings.locale];
      });
  }
  return [];
});

const qrCodeImg = ref();

function updateQrCode() {
  qrCode.generateQrCode("www.google.de").then((res) => {
    qrCodeImg.value = res;
    // console.log(res);
  });
}

const sectionsVisible = computed(() => {
  if (system_request.sections) {
    return system_request.sections.filter((section) =>
      checkVisibility(section)
    );
  }
  return [];
});

const toolbarMenuActions = computed(() => {
  return [
    {
      title: t("system_request__save_project"),
      icon: "mdi-content-save-outline",
      action: saveProject,
      ctrl_action: () => {},
      show: () => true,
    },
    {
      title: t("system_request__save_as"),
      icon: "mdi-content-copy",
      action: saveProjectAs,
      ctrl_action: () => {},
      show: () => {
        return project_active.value;
      },
    },
    {
      title: t("system_request__rename_project"),
      icon: "mdi-rename",
      action: renameProject,
      ctrl_action: () => {},
      show: () => {
        return project_active.value;
      },
    },
    {
      title: t("system_request__create_link"),
      icon: "mdi-export-variant",
      action: () => system_request.share_project(false),
      ctrl_action: () => system_request.share_project(true),
      show: () => true,
    },
    {
      title: t("system_request__export_project"),
      icon: "mdi-microsoft-excel",
      action: downloadApplicationSpecCSV,
      ctrl_action: () => {},
      show: () => true,
    },
    {
      title: t("survey_selection_survey_selection"),
      icon: "mdi-swap-horizontal",
      action: showSurveySelection,
      ctrl_action: () => {},
      show: () => true,
    },
    {
      title: t("system_request__load_autosave"),
      icon: "mdi-file-undo-outline",
      action: () => {
        autosave.load();
        system_request.get_solutions();
      },
      ctrl_action: () => {},
      show: () => true,
    },
    {
      title: t("system_request__new_search"),
      icon: "mdi-close",
      action: system_request.newSearch,
      ctrl_action: () => {},
      show: () => true,
    },
  ];
});

function showSurveySelection() {
  settings.setShowSurveySelection(true);
  // router.push("/");
}

watch(tab, () => {
  currentSection.value = sectionLabels.value[tab.value - 1];
});

function saveProject() {
  request.value["selectedSurvey"] = settings.selectedSurvey;
  if (project_active.value) {
    system_request.update_project();
  } else {
    system_request
      .create_dialog(project_prompt, system_request.request)
      .then((res) => {
        router.push({
          name: "project",
          params: { projectID: res.id },
        });
      });
  }
}

function saveProjectAs() {
  request.value["selectedSurvey"] = settings.selectedSurvey;
  system_request
    .copy_dialog(project_prompt, system_request.request)
    .then((res) => {
      router.push({ name: "project", params: { projectID: res.id } });
    });
}

function renameProject() {
  system_request
    .edit_dialog(project_prompt, system_request.project)
    .then((res) => {
      system_request.project = res;
    })
    .catch((err) => {
      if (err) {
        notification_center.push_notification(
          t("systems__project_deleted_failed"),
          err,
          NotificationType.Error
        );
      }
    });
}

onMounted(() => {
  if (props.params != null) {
    system_request.loadProjectFromParams(props.params);
  } else if (props.projectID) {
    system_request.loadProjectFromId(props.projectID);
  } else {
    system_request
      .reset()
      // .then(() => autosave.load())
      .then(() => {
        system_request.get_solutions();
      });
  }
});

function aredisjoint(set1, set2) {
  for (let i = 0; i < set1.length; i++) {
    for (let j = 0; j < set2.length; j++) {
      if (set1[i] == set2[j]) return false;
    }
  }
  return true;
}

function checkVisibility(element) {
  if (element.includeIf == null) {
    return true;
  }

  for (let c in element.includeIf) {
    let condition = element.includeIf[c];
    let selection = request.value[condition.field];
    if (selection == null) {
      return false;
    }

    if (Array.isArray(selection)) {
      if (aredisjoint(condition.values, selection)) {
        return false;
      }
    } else {
      if (!condition.values.includes(selection)) {
        return false;
      }
    }
  }
  return true;
}
</script>

<style scoped>
html,
body {
  overflow: hidden !important;
}
#application {
  display: flex !important;
  flex-direction: column;
  height: calc(100vh - 135px);
}
#applicationCardText {
  flex-grow: 1;
  overflow-y: auto;
}
.scrollable_content {
  flex-grow: 1;
  overflow-y: auto;
  overflow-x: hidden;
  max-height: calc(100vh - 180px);
}
/* .v-tab {
  font-size: 12px; 
} */
.border-left {
  border-left: 1px solid #ddd;
}
/* .v-toolbar{
  border-bottom: 1px solid #ddd;
} */
.chip-selected {
  background: #f5f5f5;
  color: black;
}
.survey-selected-class {
  background-color: white !important;
}

.survey-group {
  border-radius: 5px 5px 0px 0px;
}
/* width */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
</style>
