<template>
  <v-row no-gutters>
    <v-col cols="auto">
      <v-text-field
        label="Value"
        variant="outlined"
        v-model="model"
        :suffix="unit"
        density="compact"
        @input="debouncedEmitSliderReleased"
        width="100"
      ></v-text-field>
    </v-col>
    <v-col>
      <v-slider
        min-width="100"
        class="px-3"
        color="primary"
        v-model="model"
        :step="props.question.step"
        :max="props.question.max"
        :min="props.question.min"
        :thumb-label="showThumbLabel"
        @start="showThumbLabel = true"
        @end="
          showThumbLabel = false;
          debouncedEmitSliderReleased();
        "
      >
      </v-slider>
    </v-col>
  </v-row>
</template>

<script setup>
import { ref, computed } from "vue";
import { useDebounceFn } from "@vueuse/core";

const emit = defineEmits(["sliderReleased"]);

const model = defineModel();
const props = defineProps(["question", "section_label"]);

const unit = computed(() => {
  return props.question.unit;
});

const showThumbLabel = ref(false);

function emitSliderReleased() {
  emit("sliderReleased");
}

const debouncedEmitSliderReleased = useDebounceFn(emitSliderReleased, 300, {
  leading: true,
  trailing: false,
});
</script>

<style scoped>
.v-chip.v-chip--density-default {
  background-color: #007fc3;
}
</style>
