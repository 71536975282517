<template>
  <v-textarea
    v-model="model"
    variant="outlined"
    :placeholder="props.question.placeholder_text"
    prepend-inner-icon="mdi-text"
    density="compact"
    rows="1"
    @keydown.enter.prevent="debouncedEmitChipClicked"
  >
  </v-textarea>
</template>

<script setup>
import { useDebounceFn } from "@vueuse/core";

const props = defineProps(["question", "section_label"]);
const emit = defineEmits(["chipClicked"]);
const model = defineModel();

function emitChipClicked() {
  emit("chipClicked");
}

const debouncedEmitChipClicked = useDebounceFn(emitChipClicked, 300, {
  leading: true,
  trailing: false,
});
</script>

<style scoped></style>
