import { State } from "oidc-client-ts";
import { defineStore } from "pinia";

export const useUserStore = defineStore("user", {
  state: () => {
    return {
      sid_access_token: "" as String,
      sid_refresh_token: "" as String,
      backend_access_token: "" as String,
      backend_refresh_token: "" as String,
      firstname: "" as String,
      lastname: "" as String,
      username: "" as String,
      is_active: false as Boolean,
      is_staff: false as Boolean,
      is_superuser: false as Boolean,
      id: -1 as Number,
      groups: [] as Array<String>,
      permissions: [] as Array<String>,
    };
  },
  actions: {
    setUserinformation(firstname: String, lastname: String, username: String, is_active: Boolean, is_staff: Boolean, is_superuser: Boolean, id: Number, groups: Array<String>, permissions: Array<String>) {
      this.firstname = firstname
      this.lastname = lastname
      this.username = username
      this.is_active = is_active
      this.is_staff = is_staff
      this.is_superuser = is_superuser
      this.id = id
      this.groups = groups
      this.permissions = permissions
    },
    setSickIDCredentials(access_token: String, refresh_token: String) {
      this.sid_access_token = access_token
      this.sid_refresh_token = refresh_token
    },
    setBackendCredentials(access_token: String, refresh_token: String) {
      this.backend_access_token = access_token
      this.backend_refresh_token = refresh_token
    },
    setBackendAccessToken(access_token: String) {
      this.backend_access_token = access_token
    },
    clear() {
      this.backend_access_token = ""
      this.backend_access_token = ""
      this.firstname = ""
      this.lastname = ""
      this.username = ""
      this.is_active = false
      this.is_staff = false
      this.is_superuser = false
      this.id = -1
      this.groups = []
    },
  },
  getters: {
    isLoggedIn: (state) => {
      return state.id > 0
    },
    hasGroup: (state, group) => {
      return state.groups.includes(group)
    },
    isSuperUser: (state) => {
      return state.is_superuser
    },
    get_backend_access_token(state) {
      return state.backend_access_token
    },
    get_backend_refresh_token(state) {
      return state.backend_refresh_token
    },
    get_full_name(state) {
      return state.firstname + " " + state.lastname
    }
  },
  persist: true,
});