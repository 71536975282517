<template>

  <SurveySelection
    v-model="settings.showSurveySelection"
    :surveyList="surveys"
  ></SurveySelection>

  <v-row class="ma-0" v-show="!settings.showSurveySelection">
    <v-btn
      v-if="external_hook_source != null"
      class="send_to_external_app_btn"
      prepend-icon="mdi-share-circle"
      color="primary"
      @click="send_definition"
      >{{
        $t("home__data_exchange_with_app", {
          application_name: external_hook_source.data.app_name,
        })
      }}</v-btn
    >

    <v-col cols="12" xxl="3" xl="3" lg="4" md="5" sm="12" class="px-2 pt-6">
      <ApplicationForm
        :projectID="projectID"
        :params="params"
        :surveyList="surveys"
      />
    </v-col>

    <v-col
      cols="12"
      xxl="9"
      xl="9"
      lg="8"
      md="7"
      sm="12"
      class="px-2 pt-6 pt-3"
    >
      <v-card v-if="!settings.show_3d">
        <SolutionTable :show="!selected" @open_details="open_details" />
        <SolutionPanel
          v-if="selected"
          :system="selected"
          @unselected="selected = false"
        />
      </v-card>

      <v-card v-if="settings.show_3d">
        <!-- <SolutionAnimationRenderer /> -->
        <Madness3dViewer
          v-if="!settings.showSurveySelection"
          :application="request"
          :solution="solution"
          :config="config"
        />
      </v-card>
    </v-col>
  </v-row>
</template>

<script setup>
import { ref, onMounted, computed } from "vue";
import { storeToRefs } from "pinia";
import ApplicationForm from "@/components/ApplicationForm.vue";
import SurveySelection from "../components/SurveySelection.vue";
import SolutionTable from "@/components/SolutionTable.vue";
// import SolutionAnimationRenderer from "@/components/SolutionAnimationRenderer.vue";
import Madness3dViewer from "@/components/Madness3dViewer.vue";
import SolutionPanel from "@/components/SolutionPanel.vue";
import { useSystemRequestStore } from "@/stores/system_request";
import { useSettingsStore } from "@/stores/settings";
import { useI18n } from "vue-i18n";
import { useUserStore } from "@/stores/user";

const userStore = useUserStore();

const settings = useSettingsStore();
const props = defineProps(["projectID", "params"]);
const system_request = useSystemRequestStore();
const { selected, request } = storeToRefs(system_request);

const solution = ref({
  frame_type: "4",
  frame_width: 2000,
  frame_height: 1500,
  frame_length: 1750,
  lectors_top_rear: [-100, 100],
});

const config = ref({
  camera_position: "OVERVIEW",
  conveyor_limit_enabled: false,
  object_enabled: false,
  solution_enabled: true,
  object_spawner_enabled: true,
});

const external_hook_source = ref(null);
const { t, te } = useI18n();

const surveys = computed(() => {
  const result = [
    {
      title: t("survey_selection_survey_logistics"),
      label: "SickAG",
      icon: "/package_2.svg",
      ctrl_action: () => {},
      show: () => true,
      questionnaire: "SickAG",
      description: t("home__systemselektor_description"),
    },
    {
      title: t("survey_selection_survey_airport"),
      label: "SickAG Airport",
      icon: "/baggage_conveyor_belt.svg",
      ctrl_action: () => {},
      show: () => true,
      questionnaire: "SickAG Airport",
      description: t("home__systemselektor_description"),
    },
    // {
    //   title: "Logimat",
    //   label: "Logimat",
    //   icon: "/Logimat_logo.svg",
    //   ctrl_action: () => {},
    //   show: () => true,
    //   questionnaire: "Logimat",
    //   description: "Special Questionnaire for Logimat",
    // },
  ];
  return result
});

onMounted(() => {
  if (opener) {
    window.opener.postMessage({ protocol: "ready" }, "*");
    window.addEventListener("message", (event) => {
      if (
        event.data &&
        event.data.hasOwnProperty("app_identifier") &&
        event.data.app_identifier == "value-map"
      ) {
        external_hook_source.value = event;
      }
    });
  }
});

function send_definition() {
  external_hook_source.value.source.postMessage(
    { protocol: "result", definition: system_request.get_request_state() },
    external_hook_source.value.origin
  );
  window.close();
}

function open_details(item) {
  selected.value = item;
  window.scrollTo(0, 0);
}
</script>

<style>
.send_to_external_app_btn {
  position: fixed;
  bottom: 60px;
  right: 5px;
  z-index: 10000;
}
</style>
