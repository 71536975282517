<template>
  <iframe
    id="mif"
    ref="mf"
    :src="src_url"
    class="madness3dviewer mt-n10 mb-n2"
    allow-downloads
    @load="initScene"
  ></iframe>

  <v-overlay
    v-model="showOverlay"
    persistent
    class="d-flex align-center justify-center"
  >
    <v-progress-circular
      v-if="!glbLoaded"
      :size="70"
      :width="7"
      color="primary"
      indeterminate
    ></v-progress-circular>
  </v-overlay>

  <!-- DISPLAY QR CODE -->
  <div
    v-if="selectedSolution && selectedSolution['usdz_file']"
    style="position: absolute; top: 8px !important; right: 8px !important"
  >
    <BaseQrCode :content="selectedSolution?.['usdz_file']" size="135">
    </BaseQrCode>
  </div>

  <v-btn
    id="refresh-btn"
    icon="mdi-refresh"
    variant="tonal"
    color="primary"
    style="position: absolute; bottom: 8px !important; left: 8px !important"
    @click="initScene"
  ></v-btn>

  <v-btn
    id="refresh-btn"
    variant="tonal"
    color="primary"
    style="position: absolute; bottom: 8px !important; left: 65px !important"
    @click="config.object_spawner_enabled = !config.object_spawner_enabled"
    icon
  >
    <template #default="props">
      <v-icon v-if="config.object_spawner_enabled">mdi-stop</v-icon>
      <v-icon v-if="!config.object_spawner_enabled">mdi-play</v-icon>
    </template>
  </v-btn>

  <!-- SELECT SOLUTION -->
  <v-btn
    id="solution-menu-activator"
    append-icon="mdi-chevron-down"
    style="position: absolute; top: 8px !important; left: 8px !important"
  >
    {{ selectedSolution?.system_name ?? "Select Solution" }} ({{
      configurableSolutions.length
    }})
  </v-btn>

  <v-menu
    activator="#solution-menu-activator"
    offset-y
    transition="slide-y-transition"
  >
    <v-card max-height="75vh">
      <v-list>
        <v-list-item
          v-for="(solution, index) in configurableSolutions"
          :key="index"
          @click="
            setSelectedSolution(solution);
            load_solution(solution);
          "
          :active="
            solution.material_number === selectedSolution?.material_number
          "
        >
          <v-list-item-title>{{ solution.system_name }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-card>
  </v-menu>

  <v-btn
    v-if="userStore.isSuperUser"
    id="action-menu-activator"
    color="primary"
    append-icon="mdi-chevron-down"
    variant="tonal"
    icon="mdi-dots-vertical"
    style="position: absolute; bottom: 8px !important; left: 123px !important"
  ></v-btn>

  <v-menu
    v-if="userStore.isSuperUser"
    activator="#action-menu-activator"
    offset-y
    transition="slide-x-transition"
  >
    <v-card max-height="75vh">
      <v-list>
        <v-list-item @click="get_solution_glb()">Save GLB</v-list-item>
        <v-list-item @click="get_solution_usdz()">Save USDz</v-list-item>
        <v-list-item @click="set_solution_glb(selectedSolution?.glb_file)"
          >Load GLB</v-list-item
        >
      </v-list>
    </v-card>
  </v-menu>
</template>

<script setup>
import { onMounted, watch, ref, computed } from "vue";
import { useSystemRequestStore } from "@/stores/system_request";
import { storeToRefs } from "pinia";
import BaseQrCode from "./BaseQrCode.vue";
import { saveAs } from "file-saver";
import { useUserStore } from "@/stores/user";

const userStore = useUserStore();

const glbLoaded = ref(true);
const showOverlay = computed(() => !glbLoaded.value);

const system_request = useSystemRequestStore();
const { request, solutions } = storeToRefs(system_request);
const selectedSolution = ref(null);
const configurableSolutions = computed(
  () =>
    solutions.value.filter((s) => solutionHasValidParameters(s) || s.glb_file)
  // .concat(
  //   solutions.value.filter(
  //     (s) => !solutionHasValidParameters(s) && s.glb_file
  //   )
  // )
);

function solutionHasValidParameters(s) {
  return (
    s.system_parameters &&
    s.system_parameters?.frame_type !== "2" &&
    s.system_parameters?.frame_type !== "5"
  );
}

const fileCache = ref({});

watch(
  () => configurableSolutions,
  () => {
    if (
      !selectedSolution.value ||
      configurableSolutions.value.find(
        (s) => s.material_number === selectedSolution.value.material_number
      ) === undefined
    ) {
      selectedSolution.value = configurableSolutions.value[0];
      if (selectedSolution.value) {
        load_solution(selectedSolution.value);
      } else {
        clear_solution();
      }
    }
  },
  { deep: true }
);

function setSelectedSolution(solution) {
  selectedSolution.value = solution;
}

const props = defineProps(["application", "solution", "config"]);
const src_url = "/madness/index.html";

// CONFIG
const camera_position = computed(() => props.config.camera_position);
const conveyor_limit_enabled = computed(
  () => props.config.conveyor_limit_enabled
);
const object_enabled = computed(() => props.config.object_enabled);
const solution_enabled = computed(() => props.config.solution_enabled);
const object_spawner_enabled = computed(
  () => props.config.object_spawner_enabled
);

// APPLICATION
const conveyor_type = computed(() => props.application.conveyor_type ?? "belt");
const conveyor_width = computed(() => props.application.conveyor_width ?? 1350);
const conveyor_height = computed(
  () => props.application.conveyor_height ?? 2000
);
const conveyor_speed = computed(() => props.application.conveyor_speed ?? 1.35);

const conveyor_limits_right = computed(() => {
  return props.application.conveyor_limits_right ?? [100, 2000];
});
const conveyor_limits_left = computed(() => {
  return props.application.conveyor_limits_left ?? [100, 2000];
});
const conveyor_limits_top = computed(() => {
  return props.application.conveyor_limits_top ?? [100, 2000];
});

const object_height = computed(
  () => props.application.object_height ?? [5, 300]
);
const object_width = computed(
  () => props.application.object_width ?? [100, 600]
);
const object_length = computed(
  () => props.application.object_length ?? [100, 800]
);
const object_interval = computed(
  () => props.application.object_interval ?? 500
);
const object_guided = computed(() =>
  props.application.conveyor_object_alignment === "none"
    ? "unguided"
    : props.application.conveyor_object_alignment
); // left, center, right, unguided = none

const object_aligned = computed(
  () => props.application.object_rotation !== "omni"
);
const object_kinds = computed(() => {
  // TODO: remove the special rule for LOGIMAT survey question
  // implement the option to define such rules via the admin panel
  // if (props.application.object__LOGIMAT_object_type_dd) {
  //   props.application.object_kinds = [
  //     props.application.object__LOGIMAT_object_type_dd,
  //   ];
  //   props.application.conveyor_type =
  //     props.application.object__LOGIMAT_object_type_dd == "pallet"
  //       ? "roller"
  //       : "belt";
  //   return props.application.object__LOGIMAT_object_type_dd;
  // } else 
  if (props.application.object_kinds?.length > 0) {
    return props.application.object_kinds[0];
  }
  return "parcel";
});

const barcode_color = computed(() => props.application.barcode_color);
const barcode_background_color = computed(
  () => props.application.barcode_background_color
);
const barcode_marking_type = computed(
  () => props.application.barcode_marking_type
);
const barcode_types = computed(() => {
  return props.application.identifier?.join(",");
});

const barcode_orientation = computed(
  () => props.application.barcode_orientation
);

const barcode_positions = computed(() =>
  props.application.object_reading_sides?.map((e) => e.split("_")[1])
);

function load_solution(solution) {
  if (!solution) {
    return;
  }
  if (
    solution.system_parameters &&
    solution.system_parameters.frame_type !== "2" &&
    solution.system_parameters.frame_type !== "5"
  ) {
    load_solution_from_parameters(solution.system_parameters);
  } else if (solution.glb_file) {
    let fileUrl = solution.glb_file.split("?")?.[0];
    if (fileCache.value[fileUrl]) {
      set_solution_glb(fileCache.value[fileUrl]);
    } else {
      fetchAndCacheFile(fileUrl);
      set_solution_glb(solution.glb_file);
    }
  }
}

function fetchAndCacheFile(url) {
  fetch(url).then((response) => {
    return response.blob().then((blob) => {
      fileCache.value[url] = window.URL.createObjectURL(blob);
    });
  });
}

function load_solution_from_parameters(parameters) {
  if (!parameters) {
    return;
  }
  clear_solution();

  props.solution.frame_type = parameters.frame_type;
  set_frame_type(parameters.frame_type);

  props.solution.frame_width = parameters.frame_width_mm;
  set_frame_width(parameters.frame_width_mm);

  if (parameters.frame_type === "4") {
    // pallet systems (frame_type 4, 5)
    let mirror_pos =
      (parameters.lectors_left_front || parameters.lectors_left_rear) / 1000.0;
    set_mirror_position(-0.69 + mirror_pos);
    set_mirror_angle(parameters.mirror_right_angle);
    set_mirror_orientation(
      "left",
      parameters.lectors_left_front ? "front" : "rear"
    );
    set_mirror_orientation(
      "right",
      parameters.lectors_right_front ? "front" : "rear"
    );
  } else {
    // lector systems (frame_type 1, 2, 3)

    props.solution.frame_height = parameters.frame_height_above_conveyor;
    set_frame_height(frame_height.value);

    set_top_lectors("rear", parameters.lectors_top_rear);
    set_top_lectors("front", parameters.lectors_top_front);
    set_side_lectors("left-rear", parameters.lectors_left_rear);
    set_side_lectors("left-front", parameters.lectors_left_front);
    set_side_lectors("right-rear", parameters.lectors_right_rear);
    set_side_lectors("right-front", parameters.lectors_right_front);
  }
}

function set_side_lectors(orient, lectors) {
  if (!lectors) {
    return;
  }
  if (typeof lectors === "number") {
    lectors = [lectors];
  }
  // if (lectors.length === 2) {
  //   let distance = Math.abs(lectors[0] - lectors[1]) / 2.0;
  //   lectors = [distance, -1 * distance];
  // }
  for (let i = 0; i < lectors.length; i++) {
    add_lector(`l${orient}${i}`, `${orient}`, lectors[i], 15);
  }
}

function set_top_lectors(orient, lectors) {
  if (!lectors) {
    return;
  }
  if (typeof lectors === "number") {
    lectors = [0];
  }
  if (lectors.length === 2) {
    let distance = Math.abs(lectors[0] - lectors[1]) / 2.0;
    lectors = [distance, -1 * distance];
  }
  for (let i = 0; i < lectors.length; i++) {
    add_lector(`lt${orient}${i}`, `top-${orient}`, lectors[i], 15);
  }
}

// SOLUTION
const frame_type = computed(() => props.solution.frame_type);
const frame_width = computed(() => props.solution.frame_width);
const frame_height = computed(() => props.solution.frame_height);
const frame_length = computed(() => props.solution.frame_length);

watch(barcode_color, () => {
  const r = hexToDec(barcode_color.value.slice(1, 3));
  const g = hexToDec(barcode_color.value.slice(3, 5));
  const b = hexToDec(barcode_color.value.slice(5, 7));
  set_barcode_color(r, g, b);
});

watch(barcode_background_color, () => {
  const r = hexToDec(barcode_background_color.value.slice(1, 3));
  const g = hexToDec(barcode_background_color.value.slice(3, 5));
  const b = hexToDec(barcode_background_color.value.slice(5, 7));
  set_barcode_background_color(r, g, b);
});

// Set left min limit of conveyor belt
watch(conveyor_limits_left, () => {
  set_conveyor_limit_left_min(conveyor_limits_left.value[0]);
});

// Set left min limit of conveyor belt
watch(conveyor_limits_left, () => {
  set_conveyor_limit_left_max(conveyor_limits_left.value[1]);
});

// Set left min limit of conveyor belt
watch(conveyor_limits_right, () => {
  set_conveyor_limit_right_min(conveyor_limits_right.value[0]);
});

// Set left min limit of conveyor belt
watch(conveyor_limits_right, () => {
  set_conveyor_limit_right_max(conveyor_limits_right.value[1]);
});

// Set left min limit of conveyor belt
watch(conveyor_limits_top, () => {
  set_conveyor_limit_top_min(conveyor_limits_top.value[0]);
});

// Set left min limit of conveyor belt
watch(conveyor_limits_top, () => {
  set_conveyor_limit_top_max(conveyor_limits_top.value[1]);
});

// Set state of specific objects
watch(conveyor_limit_enabled, () => {
  set_object_state("CONVEYOR_LIMIT", conveyor_limit_enabled.value);
});

// Set state of specific objects
watch(object_enabled, () => {
  set_object_state("OBJECT", object_enabled.value);
});

// Set state of specific objects
watch(solution_enabled, () => {
  set_object_state("SOLUTION", solution_enabled.value);
});

// Set state of specific objects
watch(object_spawner_enabled, () => {
  set_object_state("OBJECT_SPAWNER", object_spawner_enabled.value);
});

// camera_position
watch(camera_position, () => {
  set_camera_position(camera_position.value);
});

// conveyor_type
watch(conveyor_type, () => {
  set_conveyor_type(conveyor_type.value);
});

// conveyor_width
watch(conveyor_width, () => {
  set_conveyor_width(conveyor_width.value);
});

// conveyor_height
watch(conveyor_height, () => {
  set_conveyor_height(conveyor_height.value); // conveyor
  if (
    selectedSolution.value &&
    solutionHasValidParameters(selectedSolution.value)
  ) {
    load_solution(selectedSolution.value);
  }
  // set_frame_height(frame_height.value);
  // set_top_rear_lectors(selectedSolution.value.system_parameters.lectors_top_rear);

  // set_conveyor_type(conveyor_type.value);
  // set_conveyor_width(conveyor_width.value);
  // set_conveyor_height(conveyor_height.value);
  // set_conveyor_speed(conveyor_speed.value);
});

// conveyor speed
watch(conveyor_speed, () => {
  set_conveyor_speed(conveyor_speed.value);
});

// object height
watch(object_height, () => {
  set_object_height_min(object_height.value[0]);
  set_object_height_max(object_height.value[1]);
});

// object length
watch(object_length, () => {
  set_object_length_min(object_length.value[0]);
  set_object_length_max(object_length.value[1]);
});

watch(object_width, () => {
  set_object_width_min(object_width.value[0]);
  set_object_width_max(object_width.value[1]);
});

watch(object_interval, () => {
  set_object_interval(object_interval.value);
});

watch(object_guided, () => {
  set_object_guided(object_guided.value);
});

watch(object_aligned, () => {
  set_object_aligned(object_aligned.value);
});

watch(object_kinds, () => {
  set_object_kind(object_kinds.value);
});

watch(barcode_positions, () => {
  set_barcode_positions(barcode_positions.value);
});

watch(barcode_types, () => {
  set_barcode_types(barcode_types.value);
});

watch(barcode_orientation, () => {
  set_barcode_orientation(barcode_orientation.value);
});

watch(barcode_marking_type, () => {
  set_barcode_marking_type(barcode_marking_type.value);
});

watch(frame_type, () => {
  set_frame_type(frame_type.value);
});

watch(frame_width, () => {
  set_frame_width(frame_width.value);
});

watch(frame_height, () => {
  set_frame_height(frame_height.value);
  // remove_lector("l1")
  // add_lector("l1", "top-rear", -400, 15)
});

watch(frame_length, () => {
  set_frame_length(frame_length.value);
});

// Set position of barcode
function set_barcode_positions(positions) {
  const mf = document.getElementById("mif");
  mf.contentWindow.postMessage({
    event: "barcode-position",
    value: positions,
  });
}

// Set the orientation of the barcode
function set_barcode_orientation(orientation) {
  const mf = document.getElementById("mif");
  mf.contentWindow.postMessage({
    event: "barcode-orientation",
    value: orientation, // Possible values: ladder, fence, omnidirectional
  });
}

// Set the type of barcode
function set_barcode_types(types) {
  const mf = document.getElementById("mif");
  mf.contentWindow.postMessage({
    event: "barcode-type",
    value: types,
  });
}

// Set the marking type of barcode
function set_barcode_marking_type(s) {
  const mf = document.getElementById("mif");
  mf.contentWindow.postMessage({
    event: "barcode-marking-type",
    value: s, // Possible values: printed, direct, any
  });
}

// Set the color of the barcode
function set_barcode_color(r, g, b) {
  const mf = document.getElementById("mif");
  mf.contentWindow.postMessage({
    event: "barcode-color",
    r: r,
    g: g,
    b: b,
  });
}

// Set the color of the barcode background
function set_barcode_background_color(r, g, b) {
  const mf = document.getElementById("mif");
  mf.contentWindow.postMessage({
    event: "barcode-background-color",
    r: r,
    g: g,
    b: b,
  });
}

function set_object_width_min(v) {
  const mf = document.getElementById("mif");
  mf.contentWindow.postMessage({
    event: "object-width-min",
    value: v / 1000.0, // unit: meter
  });
}

function set_object_width_max(v) {
  const mf = document.getElementById("mif");
  mf.contentWindow.postMessage({
    event: "object-width-max",
    value: v / 1000.0, // unit: meter
  });
}

function set_object_height_min(v) {
  const mf = document.getElementById("mif");
  mf.contentWindow.postMessage({
    event: "object-height-min",
    value: v / 1000.0, // unit: meter
  });
}

function set_object_height_max(v) {
  const mf = document.getElementById("mif");
  mf.contentWindow.postMessage({
    event: "object-height-max",
    value: v / 1000.0, // unit: meter
  });
}

function set_object_length_min(v) {
  const mf = document.getElementById("mif");
  mf.contentWindow.postMessage({
    event: "object-length-min",
    value: v / 1000.0, // unit: meter
  });
}

function set_object_length_max(v) {
  const mf = document.getElementById("mif");
  mf.contentWindow.postMessage({
    event: "object-length-max",
    value: v / 1000.0, // unit: meter
  });
}

function set_object_interval(v) {
  const mf = document.getElementById("mif");
  mf.contentWindow.postMessage({
    event: "object-interval",
    value: v / 1000.0,
  });
}

function set_object_guided(s) {
  const mf = document.getElementById("mif");
  mf.contentWindow.postMessage({
    event: "object-guided",
    value: s, // Possible values: unguided, left, center, right
  });
}

function set_object_aligned(b) {
  const mf = document.getElementById("mif");
  mf.contentWindow.postMessage({
    event: "object-aligned",
    value: b,
  });
}

function set_conveyor_limit_left_min(v) {
  const mf = document.getElementById("mif");
  mf.contentWindow.postMessage({
    event: "conveyor-limit-left-min",
    value: v / 1000.0, // unit: meter
  });
}

function set_conveyor_limit_left_max(v) {
  const mf = document.getElementById("mif");
  mf.contentWindow.postMessage({
    event: "conveyor-limit-left-max",
    value: v / 1000.0, // unit: meter
  });
}

function set_conveyor_limit_right_min(v) {
  const mf = document.getElementById("mif");
  mf.contentWindow.postMessage({
    event: "conveyor-limit-right-min",
    value: v / 1000.0, // unit: meter
  });
}

function set_conveyor_limit_right_max(v) {
  const mf = document.getElementById("mif");
  mf.contentWindow.postMessage({
    event: "conveyor-limit-right-max",
    value: v / 1000.0, // unit: meter
  });
}

function set_conveyor_limit_top_min(v) {
  const mf = document.getElementById("mif");
  mf.contentWindow.postMessage({
    event: "conveyor-limit-top-min",
    value: v / 1000.0, // unit: meter
  });
}

function set_conveyor_limit_top_max(v) {
  const mf = document.getElementById("mif");
  mf.contentWindow.postMessage({
    event: "conveyor-limit-top-max",
    value: v / 1000.0, // unit: meter
  });
}

function set_object_state(object_type, enabled) {
  const mf = document.getElementById("mif");
  mf.contentWindow.postMessage({
    event: "object-state",
    id: object_type,
    enabled: enabled,
  });
}

function set_camera_position(position, instant = true) {
  const mf = document.getElementById("mif");
  mf.contentWindow.postMessage({
    event: "camera",
    id: position,
    instant: instant,
  });
}

function set_conveyor_type(con_type) {
  const mf = document.getElementById("mif");
  mf.contentWindow.postMessage({
    event: "conveyor-type",
    value: con_type,
  });
}

function set_conveyor_width(width) {
  const mf = document.getElementById("mif");
  mf.contentWindow.postMessage({
    event: "conveyor-width",
    value: (width + 50) / 1000.0,
  });
}

function set_conveyor_speed(speed) {
  const mf = document.getElementById("mif");
  mf.contentWindow.postMessage({
    event: "conveyor-velocity",
    value: speed,
  });
}

function set_conveyor_height(height) {
  const mf = document.getElementById("mif");
  mf.contentWindow.postMessage({
    event: "conveyor-height",
    value: height / 1000.0,
  });
}

// set frame type
function set_frame_type(s) {
  const mf = document.getElementById("mif");
  mf.contentWindow.postMessage({
    event: "frame-type",
    value: s, // Possible values: 0, 1, 2, 3 (0 -> no frame, 1 -> 1-sided, 2 -> 3-sided, 3 -> 5-sided)
  });
}

// Set width of frame
function set_frame_width(width) {
  const mf = document.getElementById("mif");
  mf.contentWindow.postMessage({
    event: "frame-width",
    value: width / 1000.0, // unit: meter
  });
}

// Set height of frame
function set_frame_height(height) {
  const mf = document.getElementById("mif");
  mf.contentWindow.postMessage({
    event: "frame-height",
    value: height / 1000.0, // unit: meter
  });
}

// Set length of frame
function set_frame_length(length) {
  const mf = document.getElementById("mif");
  mf.contentWindow.postMessage({
    event: "frame-length",
    value: length / 1000.0, // unit: meter
  });
}

function set_mirror_position(position) {
  const mf = document.getElementById("mif");
  mf.contentWindow.postMessage({
    event: "mirror-position",
    value: position, // in mm
  });
}

function set_mirror_orientation(side, orient) {
  // side = left, right (str)
  // orient = front, rear (str)
  const mf = document.getElementById("mif");
  mf.contentWindow.postMessage({
    event: `mirror-orientation-${side}`,
    value: orient, // in mm
  });
}

function set_mirror_angle(degrees) {
  const mf = document.getElementById("mif");
  mf.contentWindow.postMessage({
    event: "mirror-angle",
    value: degrees, // in degrees: 0, 15, 30, 45
  });
}

// Add lector
function add_lector(id, axis, position, rotation) {
  const mf = document.getElementById("mif");
  mf.contentWindow.postMessage({
    event: "add-lector",
    id: id, // Unique identifier (str)
    axis: axis, // Possible values: left-front, left-rear, right-front, right-rear, top-front, top-rear
    position: position / 1000.0, // unit: meter
    rotation: rotation, // unit: degrees
  });
}

// Remove lector
function remove_lector(id) {
  const mf = document.getElementById("mif");
  mf.contentWindow.postMessage({
    event: "remove-lector",
    id: id, // str Unique identifier (if no id is supplied all lectors will be removed)
  });
}

function set_solution_glb(url) {
  clear_solution();
  glbLoaded.value = false;
  const mf = document.getElementById("mif");
  mf?.contentWindow?.postMessage({
    event: "set-solution-glb",
    url: url,
  });
}

function get_solution_glb() {
  const mf = document.getElementById("mif");
  mf.contentWindow.postMessage({
    event: "get-solution-glb",
  });
}

function get_solution_usdz() {
  const mf = document.getElementById("mif");
  mf.contentWindow.postMessage({
    event: "get-solution-usdz",
  });
}

function clear_solution() {
  const mf = document.getElementById("mif");
  mf.contentWindow.postMessage({
    event: "clear-solution",
  });
}

function set_object_kind(kind) {
  const mf = document.getElementById("mif");
  mf.contentWindow.postMessage({
    event: "object-kind",
    value: kind, // Possible values: parcel, pallet
  });
}

function initScene() {
  // general settings
  set_object_state("CONVEYOR_LIMIT", conveyor_limit_enabled.value);
  set_object_state("OBJECT", object_enabled.value);
  set_object_state("SOLUTION", solution_enabled.value);
  set_object_state("OBJECT_SPAWNER", object_spawner_enabled.value);
  set_camera_position(camera_position.value);
  // set_object_kind("parcel") // "pallet"

  // conveyor
  set_conveyor_type(conveyor_type.value);
  set_conveyor_width(conveyor_width.value);
  set_conveyor_height(conveyor_height.value);
  set_conveyor_speed(conveyor_speed.value);

  // conveyor limits
  set_conveyor_limit_left_min(conveyor_limits_left.value[0]);
  set_conveyor_limit_left_max(conveyor_limits_left.value[1]);
  set_conveyor_limit_right_min(conveyor_limits_right.value[0]);
  set_conveyor_limit_right_max(conveyor_limits_right.value[1]);
  set_conveyor_limit_top_min(conveyor_limits_top.value[0]);
  set_conveyor_limit_top_max(conveyor_limits_top.value[1]);

  // objects
  set_object_height_min(object_height.value[0]);
  set_object_height_max(object_height.value[1]);
  set_object_length_min(object_length.value[0]);
  set_object_length_max(object_length.value[1]);
  set_object_width_min(object_width.value[0]);
  set_object_width_max(object_width.value[1]);
  set_object_interval(object_interval.value);
  set_object_guided(object_guided.value);
  set_object_aligned(object_aligned.value);

  //barcodes
  set_barcode_background_color(255, 255, 255);
  set_barcode_color(0, 0, 0);
  set_barcode_types(barcode_types.value);
  set_barcode_marking_type("printed");
  set_barcode_orientation("ladder");
  set_barcode_positions(barcode_positions.value);

  // frame
  set_frame_type(frame_type.value);
  set_frame_width(frame_width.value);
  set_frame_height(frame_height.value);
  set_frame_length(frame_length.value);

  // load config from parameters
  // selectedSolution.value = configurableSolutions.value[0];
  load_solution(selectedSolution.value);
}

// convert hexadecimal numbers (as string) to decimal (string)
function hexToDec(s) {
  var i,
    j,
    digits = [0],
    carry;
  for (i = 0; i < s.length; i += 1) {
    carry = parseInt(s.charAt(i), 16);
    for (j = 0; j < digits.length; j += 1) {
      digits[j] = digits[j] * 16 + carry;
      carry = (digits[j] / 10) | 0;
      digits[j] %= 10;
    }
    while (carry > 0) {
      digits.push(carry % 10);
      carry = (carry / 10) | 0;
    }
  }
  return parseInt(digits.reverse().join(""), 10);
}

onMounted(() => {
  glbLoaded.value = true;
  //   window.onmessage = async function (event) {
  //     if (event.data.event == "initialized") {
  //       initScene();
  //       window.onmessage = null;
  //     }
  //   };

  window.addEventListener("message", (message) => {
    if (message.data.event === "glb-loaded") {
      glbLoaded.value = true;
    }
    if (message.data.event === "solution-usdz-blob") {
      const filename =
        (selectedSolution?.value?.system_name ?? "solution") + ".usdz";
      saveAs(message.data.blob, filename.replace(/ /g, "_"));
    }
    if (message.data.event === "solution-glb-blob") {
      const filename =
        (selectedSolution?.value?.system_name ?? "solution") + ".glb";
      saveAs(message.data.blob, filename.replace(/ /g, "_"));
    }
    if (message.data.event == "initialized") {
      initScene();
    }
  });
});
</script>

<style>
html {
  overflow: hidden !important;
}
#application {
  display: flex !important;
  flex-direction: column;
  max-height: 100vh;
}
#applicationCardText {
  flex-grow: 1;
  overflow-y: scroll;
}
.madness3dviewer {
  flex-grow: 1;
  overflow-y: auto;
  overflow-x: hidden;
  width: 100%;
  height: calc(100vh - 94px);
  border: 0;
}

/* display: block; */
</style>
