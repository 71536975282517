<template>
  <v-img :src="qrCodeImg" alt="QR Code" :width="size" />
</template>

<script setup>
import { onMounted, ref, watch } from "vue";
import { useQrCode } from "@/composables/useQrCode";

const props = defineProps(["content", "size"]);
const qrCode = useQrCode();
const qrCodeImg = ref();

function updateQrCode() {
  qrCode.generateQrCodeShort(props.content).then((res) => {
    qrCodeImg.value = res;
  });
}

watch(
  () => props.content,
  () => {
    updateQrCode();
  }
);

onMounted(() => {
  updateQrCode();
});
</script>
