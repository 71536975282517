<template>
  <div>
    <v-chip-group
      v-model="model"
      column
      selected-class="text-primary"
      :multiple="question.type == 'multi-select'"
      filter
      @update:modelValue="debouncedEmitChipClick"
    >
      <v-chip
        v-for="q in props.question.options"
        filter
        variant="tonal"
        :value="q.label"
        selected-class="chip-selected"
      >
        {{
          $te(
            "system_request_" +
              props.section_label +
              "_" +
              props.question.label +
              "_" +
              q.label
          )
            ? $t(
                "system_request_" +
                  props.section_label +
                  "_" +
                  props.question.label +
                  "_" +
                  q.label
              )
            : q[settings.locale]
        }}
      </v-chip>
    </v-chip-group>
  </div>
</template>

<script setup>
import { useSettingsStore } from "@/stores/settings";
import { useDebounceFn } from "@vueuse/core";

const props = defineProps(["question", "section_label"]);
const emit = defineEmits(["chipClicked"]);
const model = defineModel();
const settings = useSettingsStore();

function emitChipClick() {
  emit("chipClicked");
}

const debouncedEmitChipClick = useDebounceFn(emitChipClick, 300, {
  leading: true,
  trailing: false,
});
</script>

<style scoped>
.chip-selected {
  background: #f5f5f5;
  color: black;
}
</style>
