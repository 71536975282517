<template>
  <span
    :style="{
      width: '100%',
      height: '100%',
      position: 'absolute',
      filter: 'blur(15px)',
      top: '0',
      left: '0',
      background: `url(${background}) top right no-repeat`,
      backgroundAttachment: 'fixed',
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
    }"
  ></span>
  <v-container class="mx-auto login-window">
    <v-card class="ma-5 pa-5 text-center">
      <div>
        <div class="pa-3">
          <img :src="logo" style="height: 36px" />
        </div>
        <div class="text-h4 text-grey-darken-2">
          <span class="font-weight-light">{{ splitted_app_name[0] }}</span>
          <span class="font-weight-medium">{{ splitted_app_name[1] }}</span>
        </div>
      </div>

      <div class="pa-5 pt-8">
        <v-btn
          prepend-icon="mdi-account-lock-open"
          color="primary"
          @click="login_sickid"
        >
          {{ $t("login__sickid") }}
        </v-btn>
        <v-scroll-x-transition>
          <div
            v-if="showFailedSickIdLoginWarning"
            class="mb-8 text-red text-body-2"
          >
            {{ $t("login__sickid_login_failed") }}
          </div>
        </v-scroll-x-transition>
      </div>

      <v-divider class="ma-2"></v-divider>
      <v-btn
        :prepend-icon="showAllLoginOptions ? 'mdi-menu-down' : 'mdi-menu-up'"
        variant="text"
        class="text-center pa-1 text-body-2"
        @click="showAllLoginOptions = !showAllLoginOptions"
      >
        {{ $t("login__further_login_options") }}
      </v-btn>
      <v-form
        v-if="showAllLoginOptions"
        id="loginForm"
        class="text-center mx-8 my-2"
        validate-on="login"
        @submit.prevent="login"
      >
        <v-text-field
          @update:modelValue="resetWrongCredentialsWarning"
          id="username"
          variant="underlined"
          required
          v-model="username"
          autocapitalize="off"
          :placeholder="$t('login__username')"
          :rules="usernameRules"
        />
        <v-text-field
          @update:modelValue="resetWrongCredentialsWarning"
          id="password"
          variant="underlined"
          required
          v-model="password"
          type="password"
          :placeholder="$t('login__password')"
          :rules="passwordRules"
        />
        <v-scroll-x-transition>
          <div
            v-if="showWrongCredentialsWarning"
            class="mb-8 text-red text-body-2"
          >
            {{ $t("login__username_or_password_invalid") }}
          </div>
        </v-scroll-x-transition>
        <v-row justify="end">
          <v-spacer />
          <v-btn
            :rounded="0"
            class="text-right"
            prepend-icon="mdi-account-lock-open"
            variant="text"
            type="submit"
          >
            {{ $t("login__login") }}
          </v-btn>
        </v-row>
      </v-form>
    </v-card>
  </v-container>
</template>

<script setup>
import { ref, computed, onMounted } from "vue";
import { useRouter, useRoute } from "vue-router";
import { useSickID } from "@/composables/useSickID";
import { useS3 } from "@/composables/useS3";
import { useAPI } from "@/composables/useAPI";
import { useSettingsStore } from "@/stores/settings";
import { useUserStore } from "@/stores/user";

const userStore = useUserStore();

const settings = useSettingsStore();
const s3 = useS3();
const background = s3.get(s3.Bucket.PUBLIC, "background.jpg");

const api = useAPI();
const sickID = useSickID();
const router = useRouter();
const route = useRoute();
const logo = s3.get(s3.Bucket.PUBLIC, "sick.png");

const password = ref("");
const passwordRules = [(v) => !!v || "Passwort eingeben"];

const username = ref("");
const usernameRules = [(v) => !!v || "Benutzername eingeben"];

const showAllLoginOptions = ref(false);
const showWrongCredentialsWarning = ref(false);
const showFailedSickIdLoginWarning = ref(false);

function strip_path() {
  const p = route.path.replace("/login", "");
  return p === "" ? "/" : p;
}

function resetWrongCredentialsWarning() {
  showWrongCredentialsWarning.value = false;
}

onMounted(() => {
  settings.setShowSurveySelection(settings.surveySelectionEnabled);
});

function login() {
  api
    .login(username, password)
    .then((success) => {
      router.push(strip_path());
    })
    .catch((err) => {
      console.error(err);
      console.error("Wrong Username or password");
      showWrongCredentialsWarning.value = true;
    });
}

function login_sickid() {
  sickID.login(strip_path());
}

const splitted_app_name = computed(() => {
  return "SystemSelektor".match(/[A-Z][a-z]+/g);
});
</script>

<style>
.login-window {
  max-width: 500px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
}
</style>
