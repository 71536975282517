<template>
  <v-switch
    v-model="model"
    color="primary"
    @update:model-value="debouncedEmitSwitchClicked"
    class="ml-1"
  >
  </v-switch>
</template>

<script setup>
import { useSettingsStore } from "@/stores/settings";
import { useDebounceFn } from "@vueuse/core";

const settings = useSettingsStore();
const props = defineProps(["question", "section_label"]);
const emit = defineEmits(["switchClicked"]);
const model = defineModel();

function emitSwitchClicked() {
  emit("switchClicked");
}

const debouncedEmitSwitchClicked = useDebounceFn(emitSwitchClicked, 300, {
  leading: true,
  trailing: false,
});
</script>

<style scoped></style>
