<template>
  <v-footer
    app
    max-height="28px"
    class="grey--text"
    style="font-size: x-small"
    color="transparent"
  >
    <v-row>
      <v-col cols="3" class="text-left">
        {{ app_version }}
      </v-col>
      <v-col class="text-center" cols="6">
        <v-btn
          variant="text"
          color="grey"
          size="x-small"
          :href="
            'https://www.sick.com/de/' +
            settings.locale +
            '/impressum/w/imprint'
          "
          target="_blank"
          >{{ $t("footer__imprint") }}</v-btn
        >
        |
        <v-btn
          variant="text"
          color="grey"
          size="x-small"
          :href="
            'https://www.sick.com/de/' +
            settings.locale +
            '/allgemeine-geschaeftsbedingungen/w/tac'
          "
          target="_blank"
          >{{ $t("footer__general_terms_and_conditions") }}</v-btn
        >
        |
        <v-btn
          variant="text"
          color="grey"
          size="x-small"
          :href="
            'https://www.sick.com/de/' +
            settings.locale +
            '/nutzungsbedingungen/w/terms-of-use'
          "
          target="_blank"
          >{{ $t("footer__terms_of_use") }}</v-btn
        >
        |
        <v-btn
          variant="text"
          color="grey"
          size="x-small"
          :href="
            'https://www.sick.com/de/' +
            settings.locale +
            '/sick-datenschutzerklaerung/w/dataprotection'
          "
          target="_blank"
          >{{ $t("footer__privacy_policy") }}</v-btn
        >
      </v-col>

      <v-col cols="3" class="text-right">
        Copyright © {{ new Date().getFullYear() }} Sick Vertriebs GmbH. All
        Rights reserved.
      </v-col>
    </v-row>
  </v-footer>
</template>

<script setup>
import { useSettingsStore } from "@/stores/settings";

const settings = useSettingsStore();
const app_version = import.meta.env.VITE_APP_VERSION;
</script>

<style scoped></style>
