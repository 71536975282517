import axios from "@/services/api";
import { useAPI } from "@/composables/useAPI";


export function useQrCode() {
  const basePath = import.meta.env.VITE_APP_API || "http://localhost/api";
  const api = useAPI();

  var converterEngine = function (input) {
    var bytes = new Uint8Array(input),
      i = bytes.length;
    var biStr = [];
    while (i--) {
      biStr[i] = String.fromCharCode(bytes[i]);
    }
    var base64 = window.btoa(biStr.join(""));
    return base64;
  };

  function generateQrCodeShort(url) {
    if (!url) {
      return generateQrCode("undefined");
    }
    return api.shortenApi
      .shortenCreate({ link: url })
      .then((res) => {
        // console.log(res)
        return window.location.host + "/api/s/" + res.data + "/";
      })
      .then((shortUrl) => {
        return generateQrCode(shortUrl);
      });
  }

  function generateQrCode(url) {
    let data =
      `{\r\n  "text": \"` +
      url +
      `\",\r\n  "pixel_size": "6",\r\n  "pixel_type": "normal",\r\n  "margin": "2",\r\n  "padding": "12",\r\n  "color": "#007cc1",\r\n  "finder_color": "#f39200",\r\n  "background": "#ffffff",\r\n  "border_size": "6",\r\n  "border_radius": "6",\r\n  "border_text": "",\r\n  "text_color": "#ffffff",\r\n  "logo_path": "assets/sicklogo.png",\r\n  "logo_divider": "2.5",\r\n  "correction": "H"\r\n}`;

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      // url: "https://think-tank.place.sickcn.net/services1/fb123a6f-d7ed-40a7-8040-086b5cbd1d22/qrcode",
      // url: "http://ec2-18-197-168-73.eu-central-1.compute.amazonaws.com/qrcode",
      url: basePath + "/qrr/",
      headers: {},
      data: data,
      responseType: "arraybuffer",
    };

    return axios.request(config).then((response) => {
      var base64String = converterEngine(response.data);
      return "data:image/png;base64," + base64String;
    });
  }

  return {
    generateQrCode,
    generateQrCodeShort,
  };
}
