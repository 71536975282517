<template>
  <div class="scrollable_content">  
  <v-list
    v-model:selected="open"
    mandatory
    class="stepper mb-5"
    :style="getStyle"
  >
    <!-- SURVEY TITLE -->
    <v-row no-gutters justify="end">
      <v-btn
        @click="toggleCollapse"
        variant="text"
        :icon="isCollapsed ? 'mdi-chevron-right' : 'mdi-chevron-left'"
      ></v-btn>
    </v-row>

    <v-row
      v-if="!isCollapsed"
      no-gutters
      class="mt-n9"
    >
      <v-label class="pl-2" color="primary">{{ $t("survey") }}</v-label>
      <!-- <v-col style="display: flex; align-items: center; justify-content: right"> -->
      <!-- </v-col> -->
    </v-row>

    <!-- SURVEY SELECTION -->
    <v-row no-gutters>
      <v-col v-if="!isCollapsed" cols="12" class="pl-2 pt-2 pb-2">
        <v-btn-toggle
          divided
          mandatory
          color="primary"
          base-color="grey-lighten-3"
          variant="outlined"
          density="compact"
          rounded="lg"
          v-model="settings.selectedSurvey"
        >
          <!-- class="survey-group" -->
          <v-btn
            size="small"
            v-for="survey in props.surveyList"
            :value="survey.questionnaire"
            @click="switchSurvey(survey.questionnaire)"
          >
            <v-tooltip activator="parent" location="bottom">
              {{ survey.title }}
            </v-tooltip>
            <v-icon size="25">
              <img :src="survey.icon" width="40px" />
            </v-icon>
          </v-btn>
        </v-btn-toggle>
      </v-col>
      <v-col v-else cols="12" class="pl-3 pt-1">
        <v-icon size="30">
          <img :src="selectedSurvey.icon" width="40px" />
          <v-tooltip activator="parent" location="bottom">
            {{ selectedSurvey.title }}
          </v-tooltip>
        </v-icon>
      </v-col>
    </v-row>

    <v-row
      v-if="system_request.survey.name == 'SickAG' && !isCollapsed"
      no-gutters
      class="pl-2 pt-2"
    >
      <v-label v-if="!isCollapsed">
        {{ $t("system_request__advanced_options") }}
      </v-label>
      <v-label v-else></v-label>

      <v-chip-group
        v-model="system_request.request.mode__sickag_mode"
        color="primary"
        mandatory
        column
        filter
        variant="tonal"
        class="pr-2"
        @update:model-value=""
      >
        <v-chip value="quick" :style="'border-radius: 8px;'">
          {{ $t("system_request__simple_mode") }}
        </v-chip>
        <v-chip value="detailed" :style="'border-radius: 8px;'">
          {{ $t("system_request__extended_mode") }}
        </v-chip>
      </v-chip-group>
    </v-row>

    <!-- SECTION TITLE-->
    <v-row
      no-gutters
      :class="'pl-2 pb-2 pt-2'"
      style="height: 43px"
    >
      <v-col v-if="!isCollapsed" class="pt-1">
        <v-label color="primary">{{ $t("section") }}</v-label>
      </v-col>
      <v-divider
        class="mt-3 mr-2"
        v-if="isCollapsed"
      ></v-divider>
    </v-row>

    <!-- SECTION LIST-->
     
    <v-list-item
      slim
      density="compact"
      color="primary"
      v-for="(item, i) in props.items"
      :title="item"
      :value="i + 1"
      :class="isCollapsed ? 'pl-6 mx-1' : 'pl-6 mx-1'"
      :rounded="isCollapsed ? 'lg' : 'lg'"
    >
      <template #prepend="props">
        <v-badge
          :content="i + 1"
          :color="props.isSelected ? 'primary' : 'grey-lighten-2'"
        >
          <v-tooltip v-if="isCollapsed" activator="parent" location="start">{{
            item
          }}</v-tooltip>
        </v-badge>
      </template>

      <template #title="props">
        <v-list-item-title
          v-text="item"
          class="wrap-text pt-1"
          style="font-size: 0.9em"
        ></v-list-item-title>
      </template>
    </v-list-item>
  </v-list>
  </div>
</template>

<script setup>
import { ref, watch, computed } from "vue";
import { useSettingsStore } from "@/stores/settings";
import { useSystemRequestStore } from "@/stores/system_request";
import { useI18n } from "vue-i18n";
import { useUserStore } from "@/stores/user";

const userStore = useUserStore();

const { t, te } = useI18n();
const system_request = useSystemRequestStore();
const settings = useSettingsStore();
// const { selectedSurvey } = storeToRefs(settings);

const model = defineModel();
const props = defineProps(["title", "items", "surveyList"]);
const isCollapsed = ref(false);
const open = ref([1]);

const selectedSurvey = computed(() => {
  return props.surveyList.find((el) => el.label === settings.selectedSurvey);
});

function toggleCollapse() {
  isCollapsed.value = !isCollapsed.value;
}

const getStyle = computed(() => {
  return isCollapsed.value ? "width:53px" : "width: 200px";
});

watch(
  () => props.items,
  (newVal, oldVal) => {
    let selectedItemLabel = oldVal[model.value - 1];
    if (selectedItemLabel) {
      let selectedItemIndex = newVal.findIndex(
        (el) => el === selectedItemLabel
      );
      if (selectedItemIndex !== -1) {
        model.value = selectedItemIndex + 1;
      } else {
        model.value = 1;
      }
    } else {
      model.value = 1;
    }
  }
);

watch(open, (val) => {
  model.value = val[0];
});

watch(model, (val) => {
  open.value = [val];
});

function switchSurvey(survey) {
  settings.selectedSurvey = survey;
  system_request
    .changeSurvey(survey)
    .then(() => (model.value = 1))
    .then(() => system_request.get_solutions());
}
</script>

<style scoped>
.wrap-text {
  /* white-space: normal; */
}
.list-heading {
  /* background-color: #f5f5f5; */
  border-bottom: 1px solid #ddd;
  padding-left: 0px;
  margin-left: 0px;
  color: grey;
}
.stepper {
  width: 50px;
  height: 100%;
  transition: width 100ms;
}
.scrollable_content {
  border-right: 1px solid #ddd;
  flex-grow: 1;
  overflow-y: auto;
  overflow-x: hidden;
  min-height: 100%;
  max-height: calc(100vh - 400px);
  
}

/* width */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

</style>
